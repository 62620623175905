module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#9A1087","theme_color":"#9A1087","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d61e04d9fbcbbb0166cf23ad63e5cc7f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"YOUR_GOOGLE_ANALYTICS_TRACKING_ID","cookieName":"CookieConsent","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"YOUR_GOOGLE_TAG_MANAGER_TRACKING_ID","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"YOUR_FACEBOOK_PIXEL_ID","cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"pixelId":"YOUR_TIKTOK_PIXEL_ID","cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"hjid":"YOUR_HOTJAR_ID","hjsv":"YOUR_HOTJAR_SNIPPET_VERSION","cookieName":"gatsby-gdpr-hotjar"},"environments":["production","development"]},
    }]
